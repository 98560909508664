import React from 'react'
import { defineCustomElements as ionDefineCustomElements } from '@ionic/core/loader'
import PropTypes from "prop-types"
/* Core CSS required for Ionic components to work properly */
// import '@ionic/react/css/core.css';

// /* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

// /* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';
import { AnimateSharedLayout } from "framer-motion"

import 'ionicons';

/* Theme variables */
import '../styles/grid.min.css';
import '../styles/variables.css';
import '../styles/custom-colors.css';
import '../styles/globals.css';
import '../styles/custom.css';
// import '../styles/prism.css';
import '../styles/prism.css';

import { useEffect } from 'react';
// import Prism from 'prismjs'
// import "prismjs/components/prism-typescript";
import { Link } from 'gatsby'

const Layout = ({ children, location }: any) => {
    useEffect(() => {
      ionDefineCustomElements(window)
	//   Prism.highlightAll()
    }, [])

    // const router = useLocation()

    return (
		<>
			<div id="main" className="main">
				<AnimateSharedLayout>{children}</AnimateSharedLayout>
				<div className="bottom">
					<div className="bottom-left">© {new Date().getFullYear()}</div>
					<div className="bottom-right">
						<a href="#main" className="back-btn">Back to Top</a>
					</div>
				</div>
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
